@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/mixins/flex-gap'

.arc-sites
  display: grid
  column-gap: rem-fn(20)

.as-button-group
  @include gap(10)
  display: flex

.as-reason
  border-radius: rem-fn($bs-border-radius-sm)
  margin-block-start: rem-fn(20)
  padding: rem-fn(20)
