@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import "../../../../css/functions/rem-fn"
@import "../../../../css/mixins/media"

.bs-message-thread
  &__messages
    border: rem-fn(1) solid $bs-light-grey-outline
    border-radius: rem-fn($bs-border-radius-sm)
    box-shadow: $card-box-shadow
    padding-block-start: rem-fn(30)
    padding-inline: rem-fn(30) rem-fn(8)

    .bs-scroll-area
      margin-block-end: rem-fn(30)

  &__message
    &-body
      min-width: 100%
      margin: $flex-gutter-sm 0

      .flag-post
        position: absolute
        top: 4px
        right: -32px

      span.text
        white-space: break-spaces

      &--flag
        background-color: $bs-red-light
        color: $bs-red !important

        span.text
          opacity: 0.3

      &--flagged
        color: white

      @include from($breakpoint-sm-min)
        min-width: 0
        margin: $flex-gutter-sm $flex-gutter-xl

    .user-name
      text-align: left
      margin-left: rem-fn(20)

    .avatar
      width: 30px

    &--alt
      flex-direction: row-reverse

      .user-info
        flex-direction: row-reverse

      .user-name
        margin-right: rem-fn(20)

      .bs-message-thread__message-body
        margin: $flex-gutter-sm 0

        .flag-post
          left: -32px
          right: unset

        @include from($breakpoint-sm-min)
          margin: $flex-gutter-sm $flex-gutter-xl

  &__actions
    border-end-start-radius: rem-fn($bs-border-radius-sm)
    border-end-end-radius: rem-fn($bs-border-radius-sm)
    margin-inline: rem-fn(-30) rem-fn(-8)

    .bs-upgrade
      border-radius: 0
      border-end-start-radius: rem-fn($bs-border-radius-sm)
      border-end-end-radius: rem-fn($bs-border-radius-sm)
