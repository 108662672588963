@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import "../../../../css/functions/rem-fn"
@import "../../../../css/mixins/media"
@import "../../../../css/mixins/paragraph"

.bs-message-thread
  &--blocked
    .blocked-bg
      opacity: 0.9

  .unread-messages
    &.general
      top: -32px

    text-transform: uppercase
    height: 30px
    line-height: 30px

    span
      background-color: white
      padding: 0 $flex-gutter-md

  .q-btn .q-icon
    font-size: inherit

.bs-m-t-header
  margin: rem-fn(20px) 0

  .bs-chip
    margin-top: rem-fn(10px)

    @include from($breakpoint-sm-min)
      margin-top: 0

  @include from($breakpoint-md-min)
    margin: 0

.bs-m-t-tooltip
  @include paragraph('small')
  background-color: $bs-navy-blue-medium
  border-radius: $bs-border-radius
  color: #fff
  overflow: visible

  &:after
    block-size: 0
    border-inline-start: rem-fn(6) solid transparent
    border-inline-end: rem-fn(6) solid transparent
    border-top: rem-fn(6) solid $bs-navy-blue-medium
    content: ''
    inline-size: 0
    left: 50%
    position: absolute
    top: 100%
    transform: translateX(-50%)
