@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import "../../../css/functions/rem-fn"
@import "../../../css/mixins/media"

.bs-messages
  .book-info
    border: rem-fn(1) solid $bs-light-grey-outline
    border-radius: rem-fn($bs-border-radius-sm)
    box-shadow: $card-box-shadow

    &__title
      word-break: break-word
      flex-shrink: 1

  .q-separator
    background-color: #D8F4FF

  &__menu
    .message-box
      padding-block: rem-fn(15)
      padding-inline: rem-fn(20)

      .q-item__section--avatar
        min-inline-size: rem-fn(48)

      &.q-item--active,
      &.q-router-link--active
        background-color: rgba(0, 166, 26, 0.05)

      &.q-item--active,
      &.q-router-link--active,
      &.q-router-link--active .q-item__section--side
        color: $bs-green !important
        filter: $bs-green-filter
        font-weight: bold

    &-heading
      padding-block: rem-fn(20)
      padding-inline: rem-fn(30)

      h1
        margin-block-end: 0

  @include from($breakpoint-md-min)
    display: grid
    grid-column-gap: rem-fn(30)
    grid-template-areas: 'title title' 'nav content'
    grid-template-columns: rem-fn(370px) 1fr
    grid-template-rows: auto 1fr

    h1
      grid-area: title
      margin-block-end: rem-fn(20)

.bs-card > div.bs-messages__menu
  border: rem-fn(1) solid $bs-light-grey-outline
