@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import "../../../../css/functions/rem-fn"
@import "../../../../css/mixins/media"

.bs-message
  .slide-enter-active,
  .slide-leave-active
    transition: opacity .5s

  .slide-enter
    opacity: 1

  .slide-leave-to
    opacity: 0

  &__thread
    background-color: #fff
    border: rem-fn(1) solid $bs-light-grey-outline
    border-radius: rem-fn($bs-border-radius-sm)
    box-shadow: $card-box-shadow
    overflow: hidden

    .message-count
      span
        border-radius: 50%
        background-color: $bs-light-blue-really
        padding: 6px 10px
        font-weight: bold

    @include from($breakpoint-sm-min)
      flex-wrap: nowrap


  &__thread-actions
    position: absolute
    top: 0
    bottom: 0
    right: 0

    .btn
      width: 74px
      height: 100%
      letter-spacing: 0.36px

      &.mark-read
        background-color: $bs-green-light
        color: #1d987e
        .q-icon
          filter: invert(46%) sepia(97%) saturate(355%) hue-rotate(117deg) brightness(87%) contrast(88%)

      &.delete
        background-color: $bs-red-light
        color: #7d301c
        .q-icon
          filter: brightness(0) saturate(100%) invert(15%) sepia(52%) saturate(2931%) hue-rotate(354deg) brightness(100%) contrast(84%)


  &__threads
    .toolbar,
    .q-toolbar
      height: 30px
      min-height: 30px

      .sort__date
        width: 75px

      .q-btn__wrapper
        height: 20px
        min-height: 20px

      .q-btn
        @include from($breakpoint-sm-min)
          padding-right: 8px
          margin-right: rem-fn(48px)

  .bs-checkbox__message
    .q-checkbox__inner
      font-size: 30px
      color: $bs-medium-grey

.bm-filter
  .messages__heading-title
    margin: rem-fn(20px) 0

    @include from($breakpoint-md-min)
      margin-top: 0

  @include from($breakpoint-md-min)
    display: grid
    grid-template-columns: 1fr rem-fn(360px)

.bm-message-body
  flex: 1

.bm-m-b-from
  align-items: center
  display: flex
  column-gap: rem-fn(10)
  margin: 0

.bm-m-b-details
  :nth-child(1)
    flex-basis: 100%

    @include from($breakpoint-sm-min)
      flex: 1

  :nth-child(2)
    flex-basis: 50%

    @include from($breakpoint-sm-min)
      flex-basis: unset
      padding: rem-fn(8px) rem-fn(48px)

  :nth-child(3)
    flex-basis: 50%
    text-align: right

    @include from($breakpoint-sm-min)
      flex-basis: unset
      margin-right: rem-fn(48px)

  @include from($breakpoint-sm-min)
    flex-wrap: nowrap
