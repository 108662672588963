@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'

.bs-message-thread
  &__message
    &-body
      margin-left: $flex-gutter-md
    .user-name
      text-align: left
      margin-left: $flex-gutter-md
    .avatar
      width: 30px

    &--alt
      flex-direction: row-reverse
      .user-name
        text-align: right
        margin-right: $flex-gutter-md

      .bs-message-thread__message-body
        margin-right: $flex-gutter-md
